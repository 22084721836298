/* @flow */
import React from 'react';
import Table from 'react-table';
import 'react-table/react-table.css';
import './Table.module.scss';


export default props => (
    <Table
        {...props}
        previousText="Zurück"
        nextText="Vor"
        loadingText="Wird geladen..."
        noDataText="Keine Ergebnisse"
        pageText="Seite"
        ofText="von"
        rowsText="Zeilen"
        className="-striped -highlight"
    />
);
