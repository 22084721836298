import _ from 'underscore';
import auth from './Auth';
import Globals from './Globals';

function getHeaders() {
    const headers = new Headers();
    headers.append(auth.getHeaderName(), auth.getToken());
    return headers;
}

function getInit() {
    return {
        method: 'GET',
        headers: getHeaders(),
        mode: 'cors',
        cache: 'default'
    };
}

class Ajax {
    baseUrl: string;

    constructor(props) {
        if (!_.isUndefined(props)) {
            this.baseUrl = props.baseUrl;
            if (!_.isUndefined(props.module)) {
                this.baseUrl = Globals.modules[props.module].endpointUrl;
            }
        }
        if (_.isUndefined(this.baseUrl)) {
            this.baseUrl = Globals.baseUrl;
        }
    }

    getRequest(props) {
        const myInit = getInit();
        _.extend(myInit, props);
        return new Request(this.baseUrl + props.url, myInit);
    }

    get(options) {
        const props = {};
        if (_.isString(options)) {
            props.url = options;
        }
        const myRequest = this.getRequest(props);
        return fetch(myRequest);
    }

    getJson(options) {
        return this.get(options).then(resp => resp.json());
    }

    getText(options) {
        return this.get(options).then(resp => resp.text());
    }

    static getJson(options) {
        return new Ajax().getJson(options);
    }

    post(options) {
        const props = { method: 'POST' };
        if (_.isString(options)) {
            props.url = options;
        } else {
            _.extend(props, options);
            if (props.json) {
                props.body = JSON.stringify(options.json);
                const headers = getHeaders();
                headers.append('Accept', 'application/json');
                headers.append('Content-Type', 'application/json');
                props.headers = headers;
                delete props.json;
            }
        }
        const myRequest = this.getRequest(props);
        return fetch(myRequest);
    }

    delete(options) {
        const props = { method: 'DELETE' };
        if (_.isString(options)) {
            props.url = options;
        } else {
            _.extend(props, options);
            if (props.json) {
                props.body = JSON.stringify(options.json);
                const headers = getHeaders();
                headers.append('Accept', 'application/json');
                headers.append('Content-Type', 'application/json');
                props.headers = headers;
                delete props.json;
            }
        }
        const myRequest = this.getRequest(props);
        return fetch(myRequest);
    }
}

export default Ajax;
