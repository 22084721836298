import FileSaver from 'file-saver';
import auth from './Auth';

class Download {
    /* constructor() {
    } */
    static saveCSV(csv, filename) {
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        FileSaver.saveAs(blob, filename);
    }

    static do(options) {
        const req = new XMLHttpRequest();
        req.open(options.method || 'GET', options.url, true);
        req.setRequestHeader('X-Authorisation', auth.getToken());
        req.addEventListener('progress', evt => {
            if (evt.lengthComputable) {
                const percentComplete = evt.loaded / evt.total;
                console.log(percentComplete);
            }
        }, false);
        req.responseType = 'blob';
        //
        req.onreadystatechange = () => {
            if (req.readyState === 4 && req.status === 200) {
                let { filename } = options;
                if (filename === undefined) {
                    const myHeader = req.getResponseHeader('Content-disposition');
                    const filenameSplit = myHeader.split('=');
                    filename = filenameSplit[1].replace(/"/g, '').replace(/ /g, '');
                }
                // var blob = new Blob([req.response], {type: "application/pdf"});
                const blob = new Blob([req.response]);
                FileSaver.saveAs(blob, filename, false);
                if (options.success) {
                    options.success(filename);
                }
            } else if (req.readyState === 4) {
                const blob2 = new Blob([req.response]);
                const reader = new FileReader();
                reader.onload = () => {
                    options.error(JSON.parse(reader.result));
                };
                reader.readAsText(blob2);
            }
        };
        req.send();
    }
}

export default Download;
