/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button20IconCircle.module.scss';

class Button20IconCircle extends React.Component {
    static propTypes = {
        caption: PropTypes.string,
        icon: PropTypes.string,
        handleClick: PropTypes.func,
    }

    static defaultProps = {
        caption: 'edit',
        icon: 'edit',
        handleClick: () => alert('Clicked!')
    }

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <button className={styles.Button20IconCircle + ' ' + styles[this.props.icon]} title={this.props.caption} onClick={() => this.props.handleClick()}>
                {this.props.caption}
            </button>
        );
    }
}

export default Button20IconCircle;
