/* @flow */

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import trans from '../Trans';
import './formelements.module.scss';

function zeroFill(number, width) {
    width -= number.toString().length;
    if (width > 0) {
        return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number;
    }
    return number + ''; // always return a string
}

function selectableTimes(endHour, minuteSteps, startHour = 0, startMinute = 0) {
    const options = [];
    for (let i = startHour; i <= endHour; i += 1) {
        for (let y = startMinute; y < 60; y += minuteSteps) {
            options.push({ value: zeroFill(i, 2) + ':' + zeroFill(y, 2), label: zeroFill(i, 2) + ':' + zeroFill(y, 2) });
        }
    }
    return options;
}

class FormTimepicker extends React.Component {
    static propTypes = {
        labelwidth: PropTypes.string,
        inputwidth: PropTypes.string,
        label: PropTypes.string,
        name: PropTypes.string,
        startTime: PropTypes.string,
        endTime: PropTypes.string,
        endHour: PropTypes.string,
        minuteSteps: PropTypes.number,
        onChange: PropTypes.func.isRequired,
        trans: PropTypes.object.isRequired
    };

    static defaultProps = {
        labelwidth: '8',
        inputwidth: '4',
        label: 'Foo',
        name: 'foo',
        startTime: '00:00',
        endTime: '01:00',
        endHour: '02:00',
        minuteSteps: 15
    }

    constructor(props) {
        super(props);
        this.state = {
            startTime: this.props.startTime,
            endTime: this.props.endTime
        };
    }

    componentWillReceiveProps(props) {
        this.setState({
            startTime: props.startTime,
            endTime: props.endTime
        });
    }

    changed(what) {
        this.props.onChange(
            what,
            this.state.startTime,
            this.state.endTime
        );
    }

    startTimeChange(val) {
        console.log('Starttimechange', val);
        if (parseInt(val.value.replace(':', ''), 10) > parseInt(this.state.endTime.replace(':', ''), 10)) {
            this.setState({
                startTime: val.value,
                endTime: val.value,
            }, () => this.changed('startTime'));
        } else {
            this.setState({
                startTime: val.value
            }, () => this.changed('startTime'));
        }
    }

    endTimeChange(val) {
        this.setState({
            endTime: val.value
        }, () => this.changed('endTime'));
    }

    render() {
        return (
            <div className="row">
                <div className={'col-' + this.props.labelwidth}>
                    <label htmlFor={this.props.name}>
                        {this.props.label}
                        :
                    </label>
                </div>
                <div className={'col-' + this.props.inputwidth}>
                    <div className="timePickerWrapper">
                        {this.props.endTime && (
                            <label htmlFor={this.props.name}>
                                {this.props.trans._('formTimePicker.label.from')}
                                :
                            </label>
                        )}
                        <Select
                            value={this.state.startTime}
                            options={selectableTimes(this.props.endHour, this.props.minuteSteps)}
                            onChange={this.startTimeChange.bind(this)}
                            placeholder="..."
                            clearable={false}
                            className="time"
                            arrowRenderer={() => <span className="clock" />}
                        />
                    </div>
                    {this.props.endTime && (
                        <div className="timePickerWrapper">
                            <label htmlFor={this.props.name}>
                                {this.props.trans._('formTimePicker.label.to')}
                                :
                            </label>
                            <Select
                                value={this.state.endTime}
                                options={selectableTimes(this.props.endHour, this.props.minuteSteps, parseInt(this.state.startTime.substr(0, 2), 10), parseInt(this.state.startTime.substr(3, 2), 10))}
                                onChange={this.endTimeChange.bind(this)}
                                placeholder="..."
                                clearable={false}
                                className="time"
                                arrowRenderer={() => <span className="clock" />}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default trans()(FormTimepicker);
