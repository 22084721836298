import React from 'react';
import PropTypes from 'prop-types';
import Label from './Label';
import ValidationError from './ValidationError';
import validators from './validators';
import styles from './FormInput.module.scss';

/*
const required = value => {
    if (!value.toString().trim().length) {
        // We can return string or jsx as the 'error' prop for the validated Component
        return 'require';
    }
    return false;
};
*/
// alert(aaa);
/*
const validators = {
    required
};
*/

class FormInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            validationError: false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    static get defaultProps() {
        return {
            labelwidth: '8',
            inputwidth: '4',
            label: 'Foo',
            name: 'foo',
            value: 'Bar',
            validators: []
        };
    }

    static get propTypes() {
        return {
            labelwidth: PropTypes.string,
            inputwidth: PropTypes.string,
            label: PropTypes.string,
            name: PropTypes.string,
            value: PropTypes.string,
            onChange: PropTypes.func.isRequired,
            validators: PropTypes.array
        };
    }

    handleChange(event) {
        const validation = validators.validate(event.target.value, this.props.validators);
        this.setState({
            value: event.target.value,
            validationError: validation.validationErrors
        });
        this.props.onChange(event.target.value, this.props.name, validation.validated);
    }

    render() {
        return (
            <div className={styles.forminput}>
                <div className={styles.row}>
                    <Label {...this.props} />
                    <div className={styles['col-' + this.props.inputwidth]}>
                        <input
                            type="text"
                            value={this.state.value}
                            name={this.props.name}
                            onChange={this.handleChange}
                        />
                        {this.state.validationError
                            && <ValidationError validationError={this.state.validationError} />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default FormInput;
