import uuidv4 from 'uuid';

class TestId {
    static generateID(elementName, customID = null) {
        const uuid = uuidv4();
        let id = 'tableselector-' + uuid;
        if (customID) {
            id = 'tableselector-' + customID + '-' + uuid;
        }
        return id;
    }
}

export default TestId.generateID;
