class Globals {
    version: string;

    baseUrl: string;

    rootUrl: string;

    baseLangs: string;

    user: any;

    actions: any;

    schemaUserModel: any;

    i18n: any;

    serverConfig: {
        reportjob_save_path:string,
        version: string,
        application_langs:string
    };

    nextPath: string;

    modules: any;

    interfacerunView: any;

    constructor() {
        this.baseUrl = 'http://127.0.0.1:9001/api/';
        const myLocation = window.location;
        if (typeof myLocation.origin === 'undefined') { myLocation.origin = myLocation.protocol + '//' + myLocation.host; }
        this.rootUrl = myLocation.origin;
        if (typeof window.config !== 'undefined') {
            if (typeof window.config.endpointUrl !== 'undefined') {
                this.baseUrl = window.config.endpointUrl;
                if (this.baseUrl.indexOf('/', 0) === 0) {
                    this.baseUrl = this.rootUrl + this.baseUrl;
                } else if (this.baseUrl.indexOf(':', 0) === 0) {
                    this.baseUrl = myLocation.protocol + '//' + myLocation.hostname + this.baseUrl;
                }
            }
            if (typeof window.config.modules !== 'undefined') {
                this.modules = window.config.modules;
                Object.keys(this.modules).forEach(key => {
                    if (typeof this.modules[key].endpointUrl !== 'undefined') {
                        if (this.modules[key].endpointUrl.indexOf('/', 0) === 0) {
                            this.modules[key].endpointUrl = this.rootUrl + this.modules[key].endpointUrl;
                        } else if (this.modules[key].endpointUrl.indexOf(':', 0) === 0) {
                            this.modules[key].endpointUrl = myLocation.protocol + '//' + myLocation.hostname + this.modules[key].endpointUrl;
                        }
                    }
                });
            }
        }
        this.user = null;
        this.actions = null;
        this.serverConfig = null;
        this.schemaUserModel = null;
        this.nextPath = '';
        this.i18n = null;
        this.interfacerunView = null;
    }
}

export default (new Globals());
