import Globals from './Globals';

class Auth {
    static setToken(token) {
        localStorage.token = token;
        const now = new Date().getTime();
        localStorage.token_created = now;
        localStorage.token_updated = now;
    }

    static getHeaderName() {
        return 'X-Authorisation';
    }

    static getToken() {
        localStorage.token_updated = new Date().getTime();
        return localStorage.token || '';
    }

    static getLang() {
        return localStorage.user_lang;
    }

    static setLang(lang) {
        localStorage.user_lang = lang;
    }

    static logout() {
        Globals.user = null;
        delete localStorage.token;
        delete localStorage.token_created;
        delete localStorage.token_updated;
    }

    static loggedIn() {
        return !!localStorage.token;
    }

    // onChange() {}
}

export default Auth;
