import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Globals from '../../utils/Globals';
import styles from './Document.module.scss';

class Document extends React.Component {
    static propTypes = {
        children: PropTypes.any
    };

    static defaultProps = {
        children: ''
    };

    userName: '';

    constructor(props) {
        super(props);
        this.state = {

        };
        this.userName = 'maBinso Software GmbH';
        this.version = '#dev';
        if (Globals.user !== null) {
            this.userName = Globals.user.get('firstname') + ' ' + Globals.user.get('lastname');
        }

        if (Globals.serverConfig !== null) {
            this.version = Globals.serverConfig.version;
        }

        // this.onClose = this.onClose.bind(this);
    }

    static onClose = () => {
        window.history.back();
    };

    render() {
        return (
            <div className={styles.documentHolder} id="documentheader">
                <div className={styles.documentHead}>
                    <div className={styles.documentTitle} />
                    <div className={styles.studioInfo}>
                        <div className={styles.version}>
maBinso Studio
                            {this.version}
                            {' '}
                            <Link to="/info">Lizenzhinweise</Link>
                        </div>
                        <div className={styles.username}>{this.userName}</div>
                    </div>
                    <a id="documentheader_close" role="presentation" className={styles.documentClose} onClick={Document.onClose}>&times;</a>
                </div>
                <div className={styles.documentContent}>
                    <div className={styles.documentContentInner}>{this.props.children}</div>
                </div>
            </div>
        );
    }
}
export default Document;
