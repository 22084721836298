import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../utils/I18n';

let i18n = new I18n();
export default function translate() {
    return Component => {
        class TranslationComponent extends React.Component {
            static propTypes = {
                lang: PropTypes.string,
            }

            static defaultProps = {
                lang: 'de'
            }

            constructor(props) {
                super(props);
                this.state = {
                    trans: i18n
                };
            }

            componentDidMount() {
                const that = this;
                if (i18n.lang === null) {
                    const { lang } = this.props;
                    import('../utils/i18n/Messages_' + lang + '.properties').then(data => {
                        i18n = new I18n(lang, data.default);
                        that.setState({ trans: i18n });
                    });
                }
            }

            render() {
                return <Component {...this.props} {...this.state} trans={this.state.trans} />;
            }
        }

        return TranslationComponent;
    };
}
