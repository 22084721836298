/* @flow */
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import trans from '../Trans';
import './datepicker/datepicker-cssmodules.scss';


type Props = {
}

type State = {
}

// eslint-disable-next-line react/prefer-stateless-function
class DatePicker extends React.Component<Props, State> {
    static defaultProps= {
    };

    render() {
        const { props } = this;
        require('moment/locale/de');
        return (
            <ReactDatePicker {...props} />
        );
    }
}

export default trans()(DatePicker);
