function unescapeUnicode(str) {
    // unescape unicode codes
    const codes = [];
    const code = parseInt(str.substr(2), 16);
    // if (code >= 0 && code < Math.pow(2, 16)) {
    if (code >= 0 && code < 2 ** 16) {
        codes.push(code);
    }
    // convert codes to text
    let unescaped = '';
    for (let i = 0; i < codes.length; i += 1) {
        unescaped += String.fromCharCode(codes[i]);
    }
    return unescaped;
}

class I18n {
    data: any;

    lang: string;

    _:any;

    constructor(lang, data) {
        this.data = {};
        if (typeof data !== 'undefined') {
            this.parse(data);
            this.lang = lang;
        } else {
            this.lang = null;
        }
        this._ = this.prop.bind(this);
    }

    prop(key) {
        let value = this.data[key];
        if (typeof value === 'undefined' || value === null) { return '[' + key + ']'; }

        // Place holder replacement
        /**
         * Tested with:
         *   test.t1=asdf ''{0}''
         *   test.t2=asdf '{0}' '{1}'{1}'zxcv
         *   test.t3=This is \"a quote" 'a''{0}''s'd{fgh{ij'
         *   test.t4="'''{'0}''" {0}{a}
         *   test.t5="'''{0}'''" {1}
         *   test.t6=a {1} b {0} c
         *   test.t7=a 'quoted \\ s\ttringy' \t\t x
         *
         * Produces:
         *   test.t1, p1 ==> asdf 'p1'
         *   test.t2, p1 ==> asdf {0} {1}{1}zxcv
         *   test.t3, p1 ==> This is "a quote" a'{0}'sd{fgh{ij
         *   test.t4, p1 ==> "'{0}'" p1{a}
         *   test.t5, p1 ==> "'{0}'" {1}
         *   test.t6, p1 ==> a {1} b p1 c
         *   test.t6, p1, p2 ==> a p2 b p1 c
         *   test.t6, p1, p2, p3 ==> a p2 b p1 c
         *   test.t7 ==> a quoted \ s    tringy          x
         */

        let i;
        if (typeof (value) === 'string') {
            // Handle escape characters. Done separately from the tokenizing loop below because escape characters are
            // active in quoted strings.
            i = 0;
            // eslint-disable-next-line
            while ((i = value.indexOf('\\', i)) !== -1) {
                if (value[i + 1] === 't') { // tab
                    value = value.substring(0, i) + '\t' + value.substring((i += 1) + 2);
                } else if (value[i + 1] === 'r') { // return
                    value = value.substring(0, i) + '\r' + value.substring((i += 1) + 2);
                } else if (value[i + 1] === 'n') { // line feed
                    value = value.substring(0, i) + '\n' + value.substring((i += 1) + 2);
                } else if (value[i + 1] === 'f') { // form feed
                    value = value.substring(0, i) + '\f' + value.substring((i += 1) + 2);
                } else if (value[i + 1] === '\\') { // \
                    value = value.substring(0, i) + '\\' + value.substring((i += 1) + 2);
                } else { // Quietly drop the character
                    value = value.substring(0, i) + value.substring(i + 1);
                }
            }

            // Lazily convert the string to a list of tokens.
            const arr = [];
            let j;
            let index;
            i = 0;
            while (i < value.length) {
                if (value[i] === '\'') {
                    // Handle quotes
                    if (i === value.length - 1) { // Silently drop the trailing quote
                        value = value.substring(0, i);
                    } else if (value[i + 1] === '\'') { // Escaped quote
                        value = value.substring(0, i) + value.substring(i += 1);
                    } else {
                        // Quoted string
                        j = i + 2;
                        // eslint-disable-next-line
                        while ((j = value.indexOf('\'', j)) !== -1) {
                            if (j === value.length - 1 || value[j + 1] !== '\'') {
                                // Found start and end quotes. Remove them
                                value = value.substring(0, i) + value.substring(i + 1, j) + value.substring(j + 1);
                                i = j - 1;
                                break;
                            } else {
                                // Found a double quote, reduce to a single quote.
                                value = value.substring(0, j) + value.substring(j += 1);
                            }
                        }

                        if (j === -1) {
                            // There is no end quote. Drop the start quote
                            value = value.substring(0, i) + value.substring(i + 1);
                        }
                    }
                } else if (value[i] === '{') {
                    // Beginning of an unquoted place holder.
                    j = value.indexOf('}', i + 1);
                    if (j === -1) { // No end. Process the rest of the line. Java would throw an exception
                        i += 1;
                    } else {
                        // Add 1 to the index so that it aligns with the function arguments.
                        index = parseInt(value.substring(i + 1, j), 10);
                        if (!Number.isNaN(index) && index >= 0) {
                            // Put the line thus far (if it isn't empty) into the array
                            const s = value.substring(0, i);
                            if (s !== '') { arr.push(s); }
                            // Put the parameter reference into the array
                            arr.push(index);
                            // Start the processing over again starting from the rest of the line.
                            i = 0;
                            value = value.substring(j + 1);
                        } else { i = j + 1; } // Invalid parameter. Leave as is.
                    }
                } else { i += 1; }
            }

            // Put the remainder of the no-empty line into the array.
            if (value !== '') { arr.push(value); }
            value = arr;

            // Make the array the value for the entry.
            this.data[key] = arr;
        }

        if (value.length === 0) { return ''; }
        if (value.lengh === 1 && typeof (value[0]) === 'string') { return value[0]; }

        let s = '';
        /* eslint-disable */
        for (i = 0; i < value.length; i += 1) {
            if (typeof (value[i]) === 'string') {
                s += value[i];
            } else if (value[i] + 1 < arguments.length) {
                s += arguments[value[i] + 1];
            } else {
                s += '{' + value[i] + '}';
            }
        }
        /* eslint-enable */
        return s;
    }

    parse(data) {
        if (data) {
            const parameters = data.toString().split(/\n/);
            // var regPlaceHolder = /(\{\d+\})/g;
            // var regRepPlaceHolder = /\{(\d+)\}/g;
            const unicodeRE = /(\\u.{4})/ig;
            for (let i = 0; i < parameters.length; i += 1) {
                parameters[i] = parameters[i].replace(/^\s\s*/, '').replace(/\s\s*$/, ''); // trim
                if (parameters[i].length > 0 && parameters[i].match('^#') !== '#') { // skip comments
                    const pair = parameters[i].split('=');
                    if (pair.length > 0) {
                        /** Process key & value */
                        const name = unescape(pair[0]).replace(/^\s\s*/, '').replace(/\s\s*$/, ''); // trim
                        let value = pair.length === 1 ? '' : pair[1];
                        // process multi-line values
                        while (value.match(/\\$/) === '\\') {
                            value = value.substring(0, value.length - 1);
                            value += parameters[i += 1].replace(/\s\s*$/, ''); // right trim
                        }
                        // Put values with embedded '='s back together
                        for (let s = 2; s < pair.length; s += 1) { value += '=' + pair[s]; }
                        value = value.replace(/^\s\s*/, '').replace(/\s\s*$/, ''); // trim

                        /** Mode: bundle keys in a map */

                        // handle unicode chars possibly left out
                        const unicodeMatches = value.match(unicodeRE);
                        if (unicodeMatches) {
                            for (let u = 0; u < unicodeMatches.length; u += 1) {
                                value = value.replace(unicodeMatches[u], unescapeUnicode(unicodeMatches[u]));
                            }
                        }
                        // add to map
                        this.data[name] = value;
                    } // END: if(pair.length > 0)
                } // END: skip comments
            }
            // eval(parsed);
            return this.data;
        }
        return null;
    }

    static browserLang() {
        return this.normaliseLanguageCode(navigator.language /* Mozilla */ || navigator.userLanguage /* IE */);
    }

    static normaliseLanguageCode(lang) {
        lang = lang.toLowerCase();
        if (lang.length > 3) {
            lang = lang.substring(0, 2);// + lang.substring(3).toUpperCase();
        }
        return lang;
    }
}

export default I18n;
