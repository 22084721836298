/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import trans from '../Trans';
import Spinner from '../Spinner/Spinner';
import '../../css/react-select.css';
import TableOfContentsChapter from './TableOfContentsChapter';
import styles from './TableOfContents.module.scss';

function dateConverter(value) {
    const output = value;
    return output;
}

class TableOfContents extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        data: PropTypes.any,
        rows: PropTypes.object,
        handleClick: PropTypes.func,
        trans: PropTypes.func.isRequired,
        selectedIndex: PropTypes.number
    };

    static defaultProps = {
        id: 'tableofcontents',
        handleClick: id => alert(id + ' Handle outside'),
        data: [],
        rows: { id: 'id', h1: 'h1', h2: 'h2' },
        selectedIndex: -1
    };

    constructor(props) {
        super(props);

        this.state = {
            orderDir: 'ASC',
            data: this.props.data,
            selectedIndex: this.props.selectedIndex,
            orderBy: 'title'
        };
    }

    componentDidMount() {
        this.sort('title', 'ASC');
    }

    componentWillReceiveProps(props) {
        this.setState({ data: props.data, selectedIndex: props.selectedIndex });
    }

    sortFields() {
        return [
            { value: 'title', label: this.props.trans._('tableOfContents.sortBar.title') },
            { value: 'subtitle', label: this.props.trans._('tableOfContents.sortBar.subtitle') }
        ];
    }

    optionsOrder() {
        return [
            { value: 'ASC', label: this.props.trans._('tableOfContents.sortBar.direction.select.asc') },
            { value: 'DESC', label: this.props.trans._('tableOfContents.sortBar.direction.select.desc') }
        ];
    }

    sort(orderBy, orderDir) {
        const { data } = this.state;
        data.sort((indexA, indexB) => {
            const valueA = indexA[orderBy];
            const valueB = indexB[orderBy];
            let sortVal = 0;
            if (valueA > valueB) {
                sortVal = 1;
            }
            if (valueA < valueB) {
                sortVal = -1;
            }
            if (sortVal !== 0 && orderDir === 'DESC') {
                sortVal *= -1;
            }
            return sortVal;
        });
        console.log('Sortiert: ', data);
        this.setState({ orderBy, orderDir, data });
    }

    render() {
        const self = this;
        return (
            <div className={styles.TableOfContents} id={this.props.id}>
                <div className={styles.sortHolder}>
                    <label>{this.props.trans._('tableOfContents.sortBar.direction.label')}</label>
                    <Select
                        className="filterSelect order"
                        value={this.state.orderDir}
                        options={this.optionsOrder()}
                        onChange={val => {
                            self.sort(self.state.orderBy, val.value);
                        }}
                        clearable={false}
                    />
                    <label>{this.props.trans._('tableOfContents.sortBar.by.label')}</label>
                    <Select
                        className="filterSelect order"
                        value={this.state.orderBy}
                        options={this.sortFields()}
                        onChange={val => {
                            self.sort(val.value, self.state.orderDir);
                        }}
                        clearable={false}
                    />
                </div>
                <div className={styles.chapterHolder}>
                    {this.state.data.length > 0
                        ? (
                            <div>
                                {this.state.data.map(p => (
                                    <TableOfContentsChapter
                                        key={p[this.props.rows.id]}
                                        selected={this.state.selectedIndex === p[this.props.rows.id]}
                                        handleClick={this.props.handleClick}
                                        id={p[this.props.rows.id]}
                                        h1={p[this.props.rows.h1]}
                                        h2={dateConverter(p[this.props.rows.h2])}
                                    />
))}
                            </div>
)
                        : <Spinner id="dashboardHolder" mode="s" load text={this.props.trans._('tableOfContents.loading')} />
                    }
                </div>
            </div>
        );
    }
}

export default trans()(TableOfContents);
