import Select from 'react-select';
import CheckBox from './components/FormElements/CheckBox';
import DatePicker from './components/FormElements/DatePicker';
import ValidationError from './components/FormElements/ValidationError';
import Validators from './components/FormElements/validators';

export default {
    // eslint-disable-next-line import/prefer-default-export
    DatePicker,
    Select,
    CheckBox,
    Validators,
    ValidationError
};
