import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { _ } from 'underscore';
import de from 'date-fns/locale/de';
import trans from '../Trans';
import './datepicker/datepicker.scss';
import './datepicker/datepicker-custom.scss';
import './formelements.module.scss';

registerLocale('de', de);

class FormDatepicker extends React.Component {
    static zeroFill(number, width) {
        width -= number.toString().length;
        if (width > 0) {
            return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number;
        }
        return number + ''; // always return a string
    }

    static selectableTimes(endHour, minuteSteps) {
        const options = [];
        for (let i = 0; i <= endHour; i += 1) {
            for (let y = 0; y < 60; y += minuteSteps) {
                options.push({ value: FormDatepicker.zeroFill(i, 2) + ':' + FormDatepicker.zeroFill(y, 2), label: FormDatepicker.zeroFill(i, 2) + ':' + FormDatepicker.zeroFill(y, 2) });
            }
        }
        return options;
    }

    static propTypes = {
        startDate: PropTypes.object,
        endDate: PropTypes.object,
        startTime: PropTypes.object,
        endTime: PropTypes.object,
        labelwidth: PropTypes.string,
        inputwidth: PropTypes.string,
        label: PropTypes.string,
        name: PropTypes.string,
        showMinutes: PropTypes.bool,
        minDate: PropTypes.any,
        maxDate: PropTypes.any,
        onChange: PropTypes.func.isRequired,
        trans: PropTypes.object.isRequired,
        endHour: PropTypes.number,
        minuteSteps: PropTypes.number
    };

    static defaultProps = {
        labelwidth: '8',
        inputwidth: '4',
        label: 'Foo',
        name: 'foo',
        startDate: new Date(),
        endDate: false,
        showMinutes: false,
        minDate: false,
        maxDate: false,
        endHour: 0,
        minuteSteps: 1,
        startTime: {},
        endTime: {}
    };

    constructor(props) {
        super(props);
        this.state = {
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            startTime: this.props.startTime,
            endTime: this.props.endTime,
            minDate: this.props.minDate,
            maxDate: this.props.maxDate
        };
    }

    componentWillReceiveProps(props) {
        const newState = {};
        if (props.startDate !== undefined) {
            newState.startDate = props.startDate;
        }
        if (props.endDate !== undefined) {
            if (props.startDate > props.endDate) {
                newState.endDate = props.startDate;
            } else {
                newState.endDate = props.endDate;
            }
        }
        if (props.minDate !== undefined) {
            newState.minDate = props.minDate;
        }
        if (props.maxDate !== undefined) {
            if (props.minDate > props.maxDate) {
                newState.maxDate = props.minDate;
            } else {
                newState.maxDate = props.maxDate;
            }
        }
        if (!_.isEmpty(newState)) {
            this.setState(newState);
        }
    }

    changed(what) {
        this.props.onChange(
            what,
            this.state.startDate,
            this.state.endDate,
            this.state.startTime,
            this.state.endTime
        );
    }

    startDateChange(date) {
        this.setState({
            startDate: date
        }, () => this.changed('startDate'));
    }

    startTimeChange(val) {
        this.setState({
            startTime: val
        }, () => this.changed('startTime'));
    }

    endDateChange(date) {
        this.setState({
            endDate: date
        }, () => this.changed('endDate'));
    }

    endTimeChange(val) {
        this.setState({
            endTime: val
        }, () => this.changed('endTime'));
    }

    render() {
        return (
            <div className="row">
                <div className={'col-' + this.props.labelwidth}>
                    <label htmlFor={this.props.name}>
                        {this.props.label}
                        :
                    </label>
                </div>
                <div className={'col-' + this.props.inputwidth}>
                    <div className="datePickerWrapper">
                        {this.props.endDate && (
                            <label htmlFor={this.props.name}>
                                {this.props.trans._('formDatePicker.label.from')}
                                :
                            </label>
                        )}
                        <DatePicker
                            dateFormat={this.state.trans ? this.props.trans._('formDatePicker.dateFormat') : 'dd.MM.yyyy'}
                            selected={this.state.startDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.startDateChange.bind(this)}
                            minDate={this.state.minDate}
                            maxDate={this.state.maxDate}
                            locale="de"
                            showMonthDropdown
                            showYearDropdown
                            selectsStart
                        />
                        {this.props.showMinutes === true
                            ? (
                                <Select
                                    value={this.state.startTime}
                                    options={FormDatepicker.selectableTimes(this.props.endHour, this.props.minuteSteps)}
                                    onChange={this.startTimeChange.bind(this)}
                                    placeholder="..."
                                    clearable={false}
                                    className="time"
                                    arrowRenderer={() => <span className="clock" />}
                                />
                            )
                            : <div className="timePlaceholder" />
                        }
                    </div>
                    {this.props.endDate && (
                        <div className="datePickerWrapper">
                            <label htmlFor={this.props.name}>
                                {this.props.trans._('formDatePicker.label.to')}
                                :
                            </label>
                            <DatePicker
                                dateFormat={this.state.trans ? this.props.trans._('formDatePicker.dateFormat') : 'dd.MM.yyyy'}
                                selected={this.state.endDate}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onChange={this.endDateChange.bind(this)}
                                minDate={this.state.minDate}
                                maxDate={this.state.maxDate}
                                locale="de"
                                showMonthDropdown
                                showYearDropdown
                                selectsEnd
                            />
                            {this.props.showMinutes === true
                                    ? (
                                        <Select
                                            value={this.state.endTime}
                                            options={FormDatepicker.selectableTimes(this.props.endHour, this.props.minuteSteps)}
                                            onChange={this.endTimeChange.bind(this)}
                                            placeholder="..."
                                            clearable={false}
                                            className="time"
                                            arrowRenderer={() => <span className="clock" />}
                                        />
                                    )
                                    : <div className="timePlaceholder" />
                                }
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default trans()(FormDatepicker);
