import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import 'react-select/dist/react-select.css';
import SVGInline from 'react-svg-inline';
import ButtonText from '../ButtonText/ButtonText';
import styles from './ModalDialog.module.scss';
/* eslint-disable */
import attention from '!raw-loader!./attention.svg';
/* eslint-disable */

const ModalFooter = ({
    textClose, textSubmit, actionClose, actionSubmit, actionCanchel, type, validated
}) => {

    if (type === 'confirm') {
        return (
            <div className={styles.modalFooter}>
                <ButtonText caption={textClose} handleClick={() => { actionClose(); }} />
                <ButtonText disabled={!validated} caption={textSubmit} handleClick={e => { actionSubmit(e); }} />
            </div>
        );
    }
    return (
        <div className={styles.modalFooter}>
            <ButtonText caption={textClose} handleClick={() => { actionClose(); }} />
        </div>
    );
};

ModalFooter.propTypes = {
    textClose: PropTypes.string.isRequired,
    textSubmit: PropTypes.string.isRequired,
    actionClose: PropTypes.func.isRequired,
    actionSubmit: PropTypes.func.isRequired,
    actionCanchel: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
};

ModalFooter.defaultProps = {
};


class ModalDialog extends React.Component {
    static propTypes = {
        actionSubmit: PropTypes.func,
        actionCanchel: PropTypes.func,
        type: PropTypes.string,
        isModalOpen: PropTypes.bool,
        textClose: PropTypes.string,
        textSubmit: PropTypes.string,
        title: PropTypes.string,
        text: PropTypes.string,
        children: PropTypes.any,
        validated: PropTypes.bool,
    };

    static defaultProps = {
        actionSubmit: () => {},
        actionCanchel: () => {},
        type: 'confirm',
        isModalOpen: false,
        textClose: 'Canchel',
        textSubmit: 'Submit',
        title: 'Title',
        text: 'Text',
        children: false,
        validated: true
    };

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: this.props.isModalOpen,
        };
        this.closeModal = this.closeModal.bind(this);
        this.actionSubmit = this.actionSubmit.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            isModalOpen: nextProps.isModalOpen
        });
    }

    closeModal() {
        this.props.actionCanchel();
    }

    actionSubmit(e) {
        console.log(e);
        e.stopPropagation();
        this.props.actionSubmit();
    }

    render() {
        const customStyles = {
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(10, 25, 58, 0.75)',
                zIndex: 3000,
                textAlign: 'left'
            },
            content: {
                position: 'absolute',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                left: '50%',
                // right: '25vw',
                width: '400px',
                border: 'none',
                background: 'transparent',
                // overflow: 'auto',
                overflow: 'inherit',
                WebkitOverflowScrolling: 'touch',
                borderRadius: '0',
                outline: 'none',
                padding: '0',
                height: '200px'
            }
        };
        return (
            <Modal className={styles.modalDialog} isOpen={this.state.isModalOpen} style={customStyles} contentLabel="" >
                <div className="modal-content">
                    <div className={styles.modalHeader}>
                        <button type="button" className={styles.close} onClick={this.closeModal}>
                            <span aria-hidden="true">&times;</span>
                            <span className="sr-only">Close</span>
                        </button>
                        <h4 className={styles['modal-title']}>{this.props.title}</h4>
                    </div>
                    {this.props.children ?
                        <div className={styles.modalBody}>
                            {this.props.children}
                        </div> :
                        <div className={styles.modalBody}>
                            <div className={styles.row}>
                                <div className={styles['col-3'] + ' ' + styles.iconCol}>
                                    {this.props.type === 'confirm' &&
                                        <SVGInline svg={attention} cleanup={['comment', 'title']} />
                                    }
                                </div>
                                <div className={styles['col-9']}>
                                    <p>{this.props.text}</p>
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles['col-12']}>
                                    <hr className={styles.hrMidGray} />
                                </div>
                            </div>
                        </div>
                    }
                    <ModalFooter
                        textSubmit={this.props.textSubmit}
                        textClose={this.props.textClose}
                        actionClose={this.closeModal}
                        actionSubmit={this.props.actionSubmit}
                        type={this.props.type}
                        validated={this.props.validated}
                    />
                </div>
            </Modal>
        );
    }
}

export default ModalDialog;
