/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './ButtonText.module.scss';

class ButtonText extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        caption: PropTypes.string,
        handleClick: PropTypes.func,
        size: PropTypes.string,
        disabled: PropTypes.bool
    }

    static defaultProps = {
        id: null,
        caption: 'Buttontext',
        handleClick: () => alert('Clicked!'),
        size: '',
        disabled: false
    }

    constructor(props) {
        super(props);
        this.state = {
            disabled: props.disabled
        };
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.disabled !== undefined && nextProps.disabled !== this.state.disabled) {
            this.setState({ disabled: nextProps.disabled });
        }
    }

    handleClick(e) {
        e.stopPropagation();
        this.props.handleClick();
    }

    render() {
        return (
            <button id={this.props.id} disabled={this.state.disabled} className={styles[this.props.size] + ' ' + styles.ButtonText} title={this.props.caption} onClick={e => { this.handleClick(e); }}>
                {this.props.caption}
            </button>
        );
    }
}

export default ButtonText;
