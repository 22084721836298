import validator from 'validator';
import _ from 'underscore';
import I18n from '../../utils/I18n';

let i18n = new I18n();
if (i18n.lang === null) {
    const lang = 'de';
    import('./i18n/Messages_' + lang + '.properties').then(data => {
        i18n = new I18n(lang, data);
    });
}

const validators = {
    required: value => {
        if (value === undefined || value == null || !value.toString().trim().length) {
            return i18n._('validator.required');
        }
        return false;
    },
    email: value => {
        if (!validator.isEmail(value)) {
            return '"' + value + '" ' + i18n._('validator.email');
        }
        return false;
    },
    isfloat: value => {
        if (!validator.isFloat(value, { locale: 'de-DE' })) {
            return '"' + value + '" ' + i18n._('validator.float');
        }
        return false;
    },
    float: value => validators.isfloat(value)
};

validators.validate = (value, myvalidators) => {
    let validationErrors = '';
    if (myvalidators) {
        myvalidators.forEach(myvalidator => {
            let validated = true;
            let validationName = myvalidator;
            let validatedMessage = false;
            if (_.isString(myvalidator)) {
                const isName = 'is' + myvalidator.charAt(0).toUpperCase() + myvalidator.slice(1);
                if (validators[myvalidator] !== undefined) {
                    validatedMessage = validators[myvalidator](value);
                    if (validatedMessage) {
                        validated = false;
                    }
                } else if (validator[isName] !== undefined) {
                    validated = validator[isName](value);
                }
            } else {
                if (myvalidator[0] !== undefined) {
                    if (myvalidator[0] === 'matches') {
                        validated = validator.matches(value, myvalidator.regex);
                    } else if (validator[myvalidator[0]] !== undefined) {
                        validated = validator[myvalidator[0]](value, myvalidator[1]);
                    }
                }
                validationName = 'regex';
            }
            if (!validated) {
                if (validationErrors !== '') {
                    validationErrors += '<br />';
                }
                validationErrors += validatedMessage || ' ' + i18n._('validator.' + validationName);
            }
        });
    }
    return {
        validationErrors,
        validated: validationErrors === ''
    };
};

export default validators;
