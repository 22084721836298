import React from 'react';
import PropTypes from 'prop-types';
import Field from './Field';
import Label from './Label';
import ValidationError from './ValidationError';
import validators from './validators';
import styles from './FormSelect.module.scss';

function arrowRenderer() {
    return (
        <span>+</span>
    );
}

class FormSelect extends React.Component {
    static propTypes = {
        labelwidth: PropTypes.string,
        inputwidth: PropTypes.string,
        label: PropTypes.string,
        name: PropTypes.string,
        multi: PropTypes.bool,
        clearable: PropTypes.bool,
        arrowRenderer: PropTypes.any,
        validationError: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        validators: PropTypes.array,
        multiValues: PropTypes.bool
    };

    static defaultProps = {
        labelwidth: '8',
        inputwidth: '4',
        label: '',
        name: 'foo',
        multi: false,
        clearable: false,
        arrowRenderer: false,
        validationError: null,
        multiValues: false,
        validators: []
    };

    constructor(props) {
        super(props);
        this.state = {
            validationError: this.props.validationError
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.validationError !== this.props.validationError) {
            this.setState({
                validationError: nextProps.validationError
            });
        }
    }

    handleChange(val) {
        let value;
        if (val === null) {
            value = '';
        } else if (typeof val === 'object') {
            value = val;
        } else {
            ({ value } = val);
        }
        const validation = this.validate(value);
        this.setState({
            validationError: validation.validationErrors
        });
        this.props.onChange(value, this.props.name, validation.validated);
    }

    validate(value) {
        let validated = true;
        let validationErrors = '';
        this.props.validators.forEach(validator => {
            const validationError = validators[validator](value);
            if (validationError) {
                validated = false;
                // validationErrors.push(validationError);
                validationErrors += ' ' + validationError;
            }
        });
        // alert(validationErrors[0]);
        return {
            validationErrors,
            validated
        };
    }

    render() {
        if (this.props.arrowRenderer) {
            // eslint-disable-next-line
            let arrowRenderer=this.props.arrowRenderer;
        } else if (this.props.multi) {
            // eslint-disable-next-line
                let arrowRenderer=function arrowRenderer () {
                return (
                    <span>+</span>
                );
            };
        }
        if (this.props.label !== '') {
            return (
                <div id={'form_' + this.props.name} className={styles.formselect}>
                    <div className={styles.row}>
                        <Label {...this.props} />
                        <div className={styles['col-' + this.props.inputwidth]}>
                            <Field {... this.props} arrowRenderer={arrowRenderer} onChange={this.handleChange} />
                            {this.state.validationError
                                && <ValidationError validationError={this.state.validationError} />
                            }
                        </div>
                    </div>
                </div>
            );
        }
        const fullWidth = parseInt(this.props.inputwidth, 10) + parseInt(this.props.labelwidth, 10);
        return (
            <div id={'form_' + this.props.name} className={styles.formselect}>
                <div className={styles.row}>
                    <div className={styles['col-' + fullWidth]}>
                        <Field {... this.props} arrowRenderer={arrowRenderer} onChange={this.handleChange} />
                        {this.props.validationError
                            && <ValidationError validationError={this.state.validationError} />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default FormSelect;
