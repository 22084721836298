import { _ } from 'underscore';
import moment from 'moment';
import ReactTable from 'react-table';

// Polyfills for IE11
import 'mdn-polyfills/Array.prototype.find';
import 'mdn-polyfills/Array.prototype.findIndex';
import 'mdn-polyfills/Number.isNaN';

import I18n from './utils/I18n';
import Ajax from './utils/Ajax';
import Auth from './utils/Auth';
import Globals from './utils/Globals';
import Bundle from './utils/Bundle';
import Download from './utils/Download';
import Table from './components/Table/Table';
import ButtonText from './components/ButtonText/ButtonText';
import ButtonFilter from './components/ButtonFilter/ButtonFilter';
import Button20IconCircle from './components/Button20IconCircle/Button20IconCircle';
import Button22Icon from './components/Button22Icon/Button22Icon';
import DocumentHead from './components/Document/DocumentHead';
import Document from './components/Document/Document';
import DocumentHr from './components/Document/DocumentHr';
import Spinner from './components/Spinner/Spinner';
import TableStandard from './components/TableStandard/TableStandard';
import TableOfContents from './components/TableOfContents/TableOfContents';
import TableOfContentsChapter from './components/TableOfContents/TableOfContentsChapter';
import ListContentHint from './components/ListContentHint/ListContentHint';
import Filter from './components/Filter/Filter';
import ModalDialog from './components/ModalDialog/ModalDialog';
import FormInput from './components/FormElements/FormInput';
import FormDatepicker from './components/FormElements/FormDatepicker';
import FormTimepicker from './components/FormElements/FormTimepicker';
import FormSelect from './components/FormElements/FormSelect';
import form from './form';
import ColorPicker from './components/FormElements/ColorPicker';
import TableSelector from './components/FormElements/TableSelector/TableSelector';
// import Field from './components/FormElements/Field';
import Utils from './utils/Utils';

export default {
    I18n,
    Ajax,
    Auth,
    Bundle,
    Globals,
    Download,
    ButtonText,
    ButtonFilter,
    Button20IconCircle,
    Button22Icon,
    DocumentHead,
    Document,
    DocumentHr,
    form,
    Spinner,
    Table,
    TableStandard,
    TableOfContents,
    TableOfContentsChapter,
    ListContentHint,
    Filter,
    ModalDialog,
    FormInput,
    FormDatepicker,
    FormTimepicker,
    FormSelect,
    ColorPicker,
    TableSelector,
    ReactTable,
    // Field,
    moment,
    Utils,
    _
};
